
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'prismjs/themes/prism.min.css';

/* You can add global styles to this file, and also import other style files */

body {
  background-color: rgb(240, 240, 240);
}

html {
  @apply text-gray-800;
}

*,
::before,
::after {
  --tw-ring-offset-width: none;
}

a,
button,
input {
  @apply transition-all;
  @apply ease-in-out;
  @apply duration-200;
  @apply outline-none;
}

.link {
  @apply text-goblin-500;
  @apply hover:text-goblin-600;
  @apply hover:underline;
}

.list-check {
  li::before {
      content: '✓';
      @apply mr-2;
      @apply text-goblin-500;
  }
}

a:focus,
button:focus,
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus,
input:focus~.checkbox {
  @apply ring;
  @apply ring-blue-400;
}

// table
table.mat-mdc-table.telemaxx-table {
  @apply table-auto;
  @apply min-w-full;
  border-collapse: separate;
  border-spacing: 0 0.5rem !important;

  // header
  .mat-mdc-header-row {
    @apply h-12;
  }

  .mat-mdc-header-cell {
    @apply text-sm;
    @apply font-semibold;
    @apply border-b-0;
    @apply px-2;
  }

  .mat-mdc-cell:first-of-type {
    @apply rounded-l-2xl;
  }

  .mat-mdc-cell:last-of-type {
    @apply rounded-r-2xl;
  }

  // rows
  .mat-mdc-row {
    @apply h-auto;
  }

  .mat-mdc-cell {
    @apply border-b-0;
    @apply p-2;
    @apply bg-white;
  }

  .mat-mdc-cell.no-entries {
    @apply bg-transparent;
    @apply text-center;
    @apply font-medium;
    @apply text-gray-500;
    @apply p-0;
    @apply pt-32;
  }
}

.bg-graph-paper {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e5e5e5' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

// toastr
.toast-container .ngx-toastr.telemaxx-toastr {
  @apply m-0;
  @apply mb-2;
  @apply p-4;
  @apply pl-14;
  @apply w-80;
  @apply max-w-full;
  @apply rounded-xl;
  @apply bg-white;
  @apply shadow-lg;
  @apply ring-opacity-5;
  @apply ring-black;
  @apply ring-1;
  @apply text-goblin-500;
  @apply transition-all;
  @apply ease-in-out;
  @apply duration-150;
  background-position: 1rem center;

  &:hover {
    @apply cursor-pointer;
    @apply bg-gray-50;
    @apply shadow-lg;
  }

  .toast-title {
    @apply text-gray-800;
    @apply font-semibold;
    @apply text-sm;
  }

  .toast-message {
    @apply text-gray-500;
    @apply text-sm;
    @apply font-normal;
    @apply mt-1;
  }
}

.telemaxx-toastr.toast-success {
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" stroke="%233C8C3C" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 12.75L11.25 15L15 9.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.telemaxx-toastr.toast-error {
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" stroke="%23ef4444" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 9V12.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 15.75H12.0075V15.7575H12V15.75Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.telemaxx-toastr.toast-info {
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" stroke="%233b82f6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 11.25L11.2915 11.2293C11.8646 10.9427 12.5099 11.4603 12.3545 12.082L11.6455 14.918C11.4901 15.5397 12.1354 16.0573 12.7085 15.7707L12.75 15.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 8.25H12.0075V8.2575H12V8.25Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
